<template>
  <div style="margin: -14px -15px">
    <!--  病例分析  -->
    <a-spin :spinning="spinning">
      <div class="case">

        <!--    身份选择-->
        <div class="type-left">
          <a-menu
              style="width: 100%"
              :defaultSelectedKeys="selectId"
              mode="inline"
          >
            <a-menu-item
                v-for="(item,index) in templateList"
                @click="SelectIdentity(item.id,item)"
                :key="item.id">
              {{item.name}}
            </a-menu-item>
          </a-menu>
        </div>

        <!-------------------------------   内容列表区    --------------------------------------------->
        <div class="case-right">
          <div class="case-right-top">
            <span style="margin-left: 15px">名称 :</span> <a-input style="width: 300px;margin-left: 5px" placeholder="请输入名称" v-model="nameVal"/>
            <a-button
                @click="lookBtn"
                style="margin-left: 50px" type="primary">查询</a-button>
            <a-button
                @click="restBtn"
                style="margin-left: 10px" >重置</a-button>
          </div>
          <div style="margin: 15px auto;padding-left: 15px">
            <a-button
                @click="synchronization"
                style="width: 80px"
                type="primary">同步</a-button>
          </div>
          <!--      表格     -->
          <a-table
              :columns="columns"
              :data-source="caseList"
              :pagination="false"
              style="height: auto;width: 98%;margin: auto"
              :rowKey="record=>record.id">
            <span slot="department" slot-scope="text, record">
              <div v-if="record.department">
              <span v-for="item in templateList">
                <span v-if="item.d_id === record.department.toString()*1">{{item.name}}</span>
              </span>
              </div>
              <div v-else></div>
            </span>
            <span slot="status" slot-scope="text, record">
              <a-switch
                  v-model="record.status=== 'VALID' ? true:false"
                  checked-children="开"
                  un-checked-children="关"
                  @change="switchChange(record.id,record.status)" />
            </span>
          </a-table>
          <div style="width: 100%;height: 50px;">
            <div style="float: right;margin-right: 20px;margin-top: 15px">
              <a-pagination
                  @change="selectPage"
                  :total="count" />
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {getCommunictionLeft, getCommunictionList, synchronization} from "../../../service/liveMeet_api";
import {putUserSwitch} from "../../../service/menuManagement_api";

export default {
  data(){
    return{
      nameVal:'',
      columns:[
        {
          title: 'userId',
          dataIndex: 'userid',
          key: 'userid',
          scopedSlots: { customRender: 'userid' },
          width:'15%'
        },
        {
          title: '名字',
          dataIndex: 'userName',
          key: 'userName',
          scopedSlots: { customRender: 'userName' },
        },
        {
          title: '手机号码',
          dataIndex: 'tel',
          key: 'tel',
          scopedSlots: { customRender: 'tel' },
        },
        {
          title: '部门',
          dataIndex: 'department',
          key: 'department',
          scopedSlots: { customRender: 'department' },
        },
        {
          title: '职位',
          dataIndex: 'company',
          key: 'company',
          scopedSlots: { customRender: 'company' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
      ],
      selectId:[0],
      spinning:false,
      //模板列表
      templateList:[],
      page_no:'',
      page_size:'',
      count:20,
      caseList:[],
      leftId:'',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"通讯录管理")
    this.getLeftList()
    this.getList()
  },
  methods:{
    //获取左侧模板列表
    async getLeftList(){
      const response = await getCommunictionLeft()
      if(response.code === 0){
        this.templateList = response.data.rows
        this.selectId[0] = response.data.rows[0].id
      }
    },
    //获取列表
    async getList(page_no,page_size,userName,departmentById) {
      this.spinning = true
      const response = await getCommunictionList(page_no,page_size,1,userName,departmentById)
      if(response.code === 0){
        this.caseList = response.data.rows
        this.count = response.data.count
      }
      this.spinning = false
    },
    SelectIdentity(id,row){
      this.selectId[0] = id
      this.leftId = row.d_id
      this.page_no = undefined
      this.page_size = undefined
      this.getList(this.page_no,this.page_size,this.nameVal,row.d_id)
    },
    selectPage(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
      this.getList(this.page_no,this.page_size,this.nameVal,this.leftId)
    },
    lookBtn(){
      this.getList(this.page_no,this.page_size,this.nameVal,this.leftId)
    },
    restBtn(){
      this.nameVal = ''
      this.getList(this.page_no,this.page_size,this.nameVal,this.leftId)
    },
    async synchronization() {
      this.spinning = true
      const response = await synchronization()
      if(response.code === 0){
        this.$message.success("同步成功",response.message)
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    delConfirm(id){
      this.$message.success("删除",id)
    },
    cancel(e) {
    },
    async switchChange(id,checked){
      if (checked === 'INVALID'){
        const data = {
          status : 'VALID'
        }
        const response =  await putUserSwitch(id,data)
        if (response.code===0){
          await this.getList(this.page_no,this.page_size,this.nameVal,this.leftId)
          this.$message.success('已开启')
        }
      }else {
        const data = {
          status : 'INVALID'
        }
        const response =  await putUserSwitch(id,data)
        if (response.code===0){
          await this.getList(this.page_no,this.page_size,this.nameVal,this.leftId)
          this.$message.success('已关闭')
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.case{
  width: 100%;
  height: auto;
  display: flex;
  .type-left{
    width: 15%;
  }
  .case-right{
    width: 85%;
    //height: auto;
    min-height: 700px;
    border-left: 15px solid #f5f5f5;
    .case-right-top{
      width: 100%;
      border-bottom: 15px solid #f5f5f5;
      height: 90px;
      padding-top: 20px;
    }
  }
}


</style>